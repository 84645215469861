import React, { useState, useEffect, createRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import i18next from "i18next";

import { Cache, useCache, CACHE } from "../../helpers/Cache";
import { fetchFAQs } from "../../redux/faqs/actions";
import { appActions } from "../../redux/app/actions";

import { ContentContainer } from "../../content/ContentContainer";
import FAQ from "../../content/FAQ";
import { NoFaqFound } from "../NoFaqFound";

import { styles } from "./styles";

const useStyles = createUseStyles(styles);

const Questions = () => {
  const { faqsStore } = Cache;
  const { FAQS_LAST_VISIT } = CACHE;
  const {
    faqs: { visibleQuestions, activeTopic, activeProduct, allTopics },
    app: { language },
  } = useSelector((state) => state);
  const TOPIC_LIST = Object.values(allTopics).map((topic) => topic.slug);
  const { t } = useTranslation();
  const [questionsToShow, setQuestionsToShow] = useState(3);
  const classes = useStyles();
  const container = createRef();
  const dispatch = useDispatch();

  const questionsByTopic = TOPIC_LIST.map((topic) => {
    if (topic === allTopics.CUSTOM_TOPIC.slug) {
      return visibleQuestions;
    }
    return visibleQuestions.filter((question) => question.topic === topic);
  });

  const questionList = questionsByTopic[TOPIC_LIST.indexOf(activeTopic)] || [];

  const handleFreshCache = async () => {
    const questions = visibleQuestions.slice(0, questionsToShow);
    const uncachedFAQs = await Cache.check(faqsStore, questions);

    if (uncachedFAQs) {
      Cache.load(faqsStore, uncachedFAQs);
    }
  };

  const handleExpiredOrNoCache = async () => {
    const faqs = await dispatch(fetchFAQs());
    const questions = faqs
      .map((faq) => visibleQuestions.find((visibleQuestion) => visibleQuestion.slug === faq.slug))
      .filter(Boolean)
      .slice(0, questionsToShow);

    Cache.load(faqsStore, questions);
  };

  useCache(handleFreshCache, handleExpiredOrNoCache, faqsStore, FAQS_LAST_VISIT, [visibleQuestions, questionsToShow, language]);

  const incrementQuestionsToShow = useCallback(() => {
    const remainderQuestionsLength = questionList.length - questionsToShow;
    const incrementValue = remainderQuestionsLength > 0 ? Math.min(remainderQuestionsLength, 20) : 0;
    setQuestionsToShow(questionsToShow + incrementValue);
  }, [questionsToShow, questionList.length]);

  useEffect(() => {
    const outerContainerDiv = container.current ? container.current : document.createElement("div");
    if (window.innerHeight * 0.5 > outerContainerDiv.offsetHeight) {
      incrementQuestionsToShow();
    }
    const handleScroll = () => {
      const windowPositionY = window.innerHeight + window.scrollY;
      const closeToBottom = document.body.clientHeight - windowPositionY < window.innerHeight;
      if (closeToBottom) {
        incrementQuestionsToShow();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [incrementQuestionsToShow, container]);

  useEffect(() => {
    language !== "en" && dispatch(appActions.setLanguage("en"));
    !i18next.language.includes("en") && i18next.changeLanguage("en");
  }, []);

  return (
    <div className={classes.questionsContainer} ref={container}>
      <h2 className={classes.questionsTopicTitle}>{`${t(activeProduct)} ${
        Object.keys(allTopics).length > 0 && activeTopic && activeTopic !== allTopics.COMMON_QUESTIONS.slug
          ? allTopics[activeTopic.toUpperCase().split("-").join("_")].name
          : ""
      }`}</h2>
      <ContentContainer>
        {questionList.length ? (
          questionList
            .slice(0, questionsToShow)
            .map(({ id, slug, title, DE }) => (
              <FAQ key={id} data={{ slug, title: language === "en" ? title : DE.title }} embedded={false} />
            ))
        ) : (
          <NoFaqFound />
        )}
      </ContentContainer>
      {/* {questionsToShow < questionList.length && <button onClick={incrementQuestionsToShow}>V</button>} */}
    </div>
  );
};

export default Questions;
