import React, { Component } from "react";
import axios from "axios";

import { COLLECTION_TYPES } from "../../helpers/Cache";
import { findByID, fetchDataFromStrapi } from "../utils";
import StopWords from "./StopWords.json";

import FAQ from "./faq/FAQ";
import Alert from "../common/Alert";
import Button from "../common/Button";
import { PRODUCTS } from "../../config";

import "./faqsCMS.styles.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: {},
      faqs: [],
    };
  }

  componentDidMount() {
    axios.get("/cms/faqs/fetch").then(({ data: { faqs } }) => {
      this.setState({ faqs });
    });
  }

  handleUpdateFAQs = async () => {
    Alert.display("info", "Updating FAQs");
    const { FAQS, TOPICS, TAGS } = COLLECTION_TYPES;
    const faqsData = await fetchDataFromStrapi(FAQS);
    const tagsData = await fetchDataFromStrapi(TAGS);
    const topicsData = await fetchDataFromStrapi(TOPICS);

    const tags = this.extractData(tagsData);
    this.setState({ tags });
    const topics = this.extractData(topicsData);
    const faqs = this.extractDataForFAQs(faqsData);

    this.updateFAQs({ topics, tags, faqs })
      .then(({ data: message }) => {
        Alert.display("success", message);
        this.setState({ faqs });
      })
      .catch(() => {
        Alert.display("error", "Couldn't update FAQs");
      });
  };

  updateFAQs = (data) => axios.put("/cms/faqs/update", data);

  extractDataForFAQs = (faqs) => {
    return faqs.map((faq) => {
      const {
        id,
        slug,
        MEATER,
        MEATER_Plus,
        MEATER_Block,
        MEATER_Pro,
        MEATER_Pro_Xl,
        MEATER_Se,
        topic,
        tags: faqTags,
        title,
        content,
        ...rest
      } = faq;

      const uniqueTags = new Set();
      MEATER && uniqueTags.add(PRODUCTS.MEATER);
      MEATER_Plus && uniqueTags.add(PRODUCTS.MEATER_PLUS);
      MEATER_Block && uniqueTags.add(PRODUCTS.MEATER_BLOCK);
      MEATER_Pro && uniqueTags.add(PRODUCTS.MEATER_PRO);
      MEATER_Pro_Xl && uniqueTags.add(PRODUCTS.MEATER_PRO_XL);
      MEATER_Se && uniqueTags.add(PRODUCTS.MEATER_SE);
      faqTags?.map(({ slug }) => uniqueTags.add(slug));
      const tags = [...uniqueTags];

      const parseContentForText = (content) => {
        const { FAQS } = COLLECTION_TYPES;
        const { type } = content;

        switch (type) {
          case "text":
            return content.value;
          case "inline-text-text":
            return content.textLeft + content.textRight;
          case "inline-text-image":
            return content.textLeft;
          case "inline-image-text":
            return content.textRight;
          case "inline-tag-tag":
            return content.tagLeft.name + content.tagRight.name;
          case "faq":
            const {
              faqRef: { id, title },
              embedded,
            } = content;
            const faq = findByID(`/${FAQS}/${id}`);
            content = faq["content"];
            return embedded ? extractTextFromContent(content) : title;
          case "faq-list":
            let faqListText = "";
            content.faqs.map(({ faqRef: { id, title }, embedded }) => {
              if (embedded) {
                const faq = findByID(`/${FAQS}/${id}`);
                content = faq["content"];
                faqListText += extractTextFromContent(content);
              } else {
                faqListText += title;
              }
            });
            return faqListText;
          default:
            return "";
        }
      };

      const extractTextFromContent = (content) =>
        content.reduce((accumulator, pieceOfContent) => (accumulator += parseContentForText(pieceOfContent)), "");

      const extractKeywords = (title, content, language = "") => {
        const textContent = extractTextFromContent(content);

        const div = document.createElement("div");
        div.innerHTML = textContent;

        let text = div.innerText;
        text += title;
        text = text.replace("’", "'");
        text = text.replace(/((({?({\s*)?product(\s*})?}?)('s)?)|\w+'s|http.*|supportapptionlabscom)/g, " ");
        text = text.replace(/[^a-zA-Z\s]/g, " ");

        const words = text.toLowerCase().split(/\s+/);
        const languageStopWords = !!language ? StopWords[language] : StopWords.default;
        const keywords = words.filter((word) => languageStopWords.indexOf(word) === -1 && word !== "");

        const { tags: TAGS } = this.state;
        const keywordsFromTags = tags
          .map((tag) => {
            const faqTag = TAGS[tag.toUpperCase().split("-").join("_")];
            const tagName = !!language ? faqTag[language].name : faqTag.name;
            return tagName?.toLowerCase().split(" ");
          })
          .flat()
          .filter(Boolean);
        keywords.push(...keywordsFromTags);

        const uniqueKeywords = [...new Set(keywords)];
        return uniqueKeywords;
      };

      const keywords = extractKeywords(title, content);
      const otherLanguages = Object.keys(rest).reduce((accumulator, currentLanguage) => {
        let { title, content } = rest[currentLanguage];
        title = title || "";
        const keywords = extractKeywords(title, content, currentLanguage);
        accumulator[currentLanguage] = { title, keywords };
        return accumulator;
      }, {});

      return {
        id,
        slug,
        topic: topic?.slug,
        tags,
        title,
        keywords,
        ...otherLanguages,
      };
    });
  };

  extractData = (data) =>
    data.reduce((accumulator, pieceOfData) => {
      const key = pieceOfData.slug.toUpperCase().split("-").join("_");
      accumulator[key] = pieceOfData;
      return accumulator;
    }, {});

  generateFAQs = () => this.state.faqs.map((faqData, index) => <FAQ key={index} data={{ ...faqData }} />);

  render() {
    return (
      <div className="faqs-cms-container">
        <h1>FAQ List</h1>
        {this.generateFAQs()}
        <Button text="Update FAQs" type="update" handleClick={this.handleUpdateFAQs} />
      </div>
    );
  }
}

export default App;
